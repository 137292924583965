<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t("crmPaymentPairingModal.pairingTransactionToPartner") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`confirmModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 mb-3 white-input">
              <label class="form-label bold-12 text-gray">{{ $t("crmPaymentPairingModal.partner") }}</label>
              <CrmItemFinder
                type="partners"
                :always-input="true"
                v-model="partnerId"
                :reduce="(c) => c.id"
              />
            </div>
            <div class="col-12">
              <label class="cstm-check d-flex" for="ct-1" v-if="paymentId">
                <span class="regular-12 text-black mt-3">
                  {{ $t("crmPaymentPairingModal.pairingPayment") }}
                </span>
                <input type="checkbox" v-model="pairingPayment" id="ct-1" />
                <span class="checkmark mt-3"></span>
              </label>
            </div>
            <div class="col-12">
              <label class="cstm-check d-flex" for="ct-1" v-if="invoiceId">
                <span class="regular-12 text-black mt-3">
                  {{ $t("crmPaymentPairingModal.pairingInvoice") }}
                </span>
                <input type="checkbox" v-model="pairingInvoice" id="ct-1" />
                <span class="checkmark mt-3"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            v-on:click="pairing(false)"
          >
            {{ $t("crmPaymentPairingModal.changeOnlyThisTransaction") }}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="pairing(true)"
          >
            {{ $t("crmPaymentPairingModal.changeEveryTransactionWithThisPartner") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrmItemFinder from "../lists/listItems/CrmItemFinder.vue";
import http from "../../modules/http";

const bootstrap = require("bootstrap");

export default {
  name: "CrmPaymentPairingModal",
  components: { CrmItemFinder },
  props: {
    invoiceId: Number,
    paymentId: Number,
  },
  data() {
    return {
      myModal: undefined,
      pairingInvoice: true,
      pairingPayment: true,
      partnerId: null,
    };
  },
  emits: ["success", "cancel"],
  methods: {
    showModal() {
      if (this.myModal === undefined) {
        this.myModal = new bootstrap.Modal(this.$refs.modal, {
          backdrop: "static",
        });
      }
      this.myModal.show();
    },
    pairing(all) {
      http
        .fetch("/payments/pairing", {
          all: all,
          crm_item_id: this.partnerId,
          invoice: this.pairingInvoice ? this.invoiceId : null,
          payment: this.pairingPayment ? this.paymentId : null,
        })
        .then(() => {
          this.myModal.hide();
          this.$emit("success");
        });
    },
  },
};
</script>
