<template>
  <div class="row">
    <div class="nature-white-box">
      <div class="white-box-top"></div>
      <div class="row">
        <TopBar
          v-model:selectAll="selectAll"
          v-model:line-options="lineOptions"
          v-model:rows="rows"
          v-model:items="items"
          :methods="methods"
          :show-currencies="false"
          :show-languages="false"
          path="finance::payments
"
          :show-add="false"
          :show-change="false"
          :show-delete="false"
          :show-select="false"
        />
        <DynamicTable
          v-model:rows="rows"
          v-model:items="items"
          v-model:select-all="selectAll"
          type="finance"
          :fix-height="true"
          :show-select="false"
          :show-pin="false"
          v-on:loadMore="loadMore"
          v-on:update:val="valUpdated"
          :key="tableKey"
        />
      </div>
    </div>
  </div>
  <Modal ref="connectModal" :title="$t(`payments.connectANewBankAccount`)">
    <div v-if="banks.length > 0">
      <h3>{{ $t("payments.selectYourBank") }}</h3>
      <div class="d-grid gap-2 grid-2">
        <button
          class="btn btn-outline-primary"
          v-for="bank in banks"
          :key="bank.id"
          v-on:click="connectRequest(bank.id, bank.provider)"
        >
          <img :src="bank.logo" class="w-25" />
          <div class="mt-2">{{ bank.name }} [{{ bank.provider }}]</div>
        </button>
      </div>
    </div>
    <div v-else>
      <h3>{{ $t("payments.selectYourCountry") }}</h3>
      <div class="d-grid gap-2 grid-2">
        <button
          class="btn btn-outline-primary"
          v-for="country in countries"
          :key="country.id"
          v-on:click="bankList(country.code)"
        >
          <BaseIcon
            :name="country?.code?.toLowerCase()"
            v-if="country?.code"
            type="flag"
            class="flag"
          />
          <div class="mt-2">
            {{ country.name }}
          </div>
        </button>
      </div>
    </div>
  </Modal>
  <Modal
    ref="accountsModal"
    :title="$t(`payments.connectedAccounts`)"
    class-name="modal-xl"
  >
    <div v-if="accounts.length > 0">
      <div class="row">
        <div
          class="col-12 mb-2 row"
          v-for="account in accounts"
          :key="account.id"
        >
          <div class="col-3">
            <EditOnClick
              v-model="account.name"
              type="text"
              v-on:update="(e) => updateAccount(account.id, e, 'name')"
            />
          </div>
          <div class="col-2 col-xxxl-1_5">
            {{ account.service }}
          </div>
          <div class="col">
            {{ account.iban }}
          </div>
          <div class="col-2" v-if="account.is_inactive">
            {{ $t("payments.inactive") }}
          </div>
          <div class="col-2" v-else>
            <EditOnClick
              :model-value="account.balance"
              type="price"
              :editable="false"
              :padding="false"
            />
          </div>
          <div class="col-2">
            <EditOnClick
              v-model="account.company_id"
              type="enum"
              :values="companies"
              select-key="id"
              label="name"
              v-on:update="(e) => updateAccount(account.id, e, 'company_id')"
            />
          </div>
        </div>
      </div>
    </div>
  </Modal>
  <CrmPaymentPairingModal
    ref="crmPaymentPairingModal"
    v-model:invoice-id="pairingInvoiceId"
    v-model:payment-id="pairingPaymentId"
  />
</template>

<script>
import http from "../../modules/http";
import date from "@/modules/date";
import { useStore } from "vuex";
import DynamicTable from "../../components/lists/DynamicTable";
import TopBar from "../../components/lists/TopBar";
import lineOptions from "../../modules/lineOptions";
import PaymentStatuses from "../../modules/PaymentStatuses";
import Modal from "@/components/modals/Modal";
import BaseIcon from "../../components/icons/BaseIcon.vue";
import EditOnClick from "../../components/inputs/EditOnClick.vue";
import PaymentTypes from "../../modules/PaymentTypes";
import CrmPaymentPairingModal from "../../components/modals/CrmPaymentPairingModal.vue";

export default {
  name: "Payments",
  components: {
    CrmPaymentPairingModal,
    EditOnClick,
    BaseIcon,
    Modal,
    TopBar,
    DynamicTable,
  },

  data() {
    return {
      colors: [
        "#4CC9F0",
        "#F9DB6D",
        "#464D77",
        "#F71735",
        "#04A777",
        "#FDFFFC",
        "#3A0CA3",
        "#F79824",
        "#4F5165",
        "#6FAE90",
        "#7D2E68",
        "#502419",
        "#645DD7",
        "#C7CB85",
        "#C7FFED",
        "#9DB5B2",
        "#55505C",
      ],
      store: useStore(),
      items: undefined,
      rows: [],
      lineOptions: [],
      selectAll: false,
      tableKey: 0,
      filtersTimeOut: null,
      firstLoad: true,
      loadID: null,
      banks: [],
      accounts: [],
      companies: [],
      cards: [],
      mcc: [],
      categories: [],
      paymentTypes: PaymentTypes,
      page: 1,
      pairingInvoiceId: null,
      pairingPaymentId: null,
      methods: [
        {
          title: "Connect",
          method: this.connect,
          always: true,
        },
        {
          title: "Accounts",
          method: this.showAccountsModal,
          always: true,
        },
      ],
    };
  },
  computed: {
    user() {
      return this.$route.params.user;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    date() {
      return this.store.state.topBar.date;
    },
    filters() {
      return this.store.state.filters?.payments;
    },
    q() {
      return this.store.state.topBar.q;
    },
    currencies() {
      return this.store.state.currencies;
    },
    countries() {
      return this.store.state.countries;
    },
    total() {
      if (this.accounts.length < 1) {
        return { amount: 0, currency: "EUR" };
      }
      let sum = 0;
      for (const account of this.accounts) {
        sum += account.converted_balance.amount;
      }
      return {
        amount: sum,
        currency: this.accounts[0].converted_balance.currency,
      };
    },
  },
  mounted() {
    this.$store.commit("setFilterPage", "payments");

    this.loadData();
    window.apps.subscribe("pair:crm_payment", (paymentId, invoiceId) => {
      this.pairingPaymentId = paymentId;
      this.pairingInvoiceId = invoiceId;
      this.$refs.crmPaymentPairingModal.showModal();
    });
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        if (this.filtersTimeOut !== null) {
          clearTimeout(this.filtersTimeOut);
        }
        this.filtersTimeOut = setTimeout(() => {
          this.filtersTimeOut = null;
          this.items = undefined;
          this.page = 1;
          this.loadData();
        }, 300);
      },
    },
    q() {
      if (this.filtersTimeOut !== null) {
        clearTimeout(this.filtersTimeOut);
      }
      this.filtersTimeOut = setTimeout(() => {
        this.filtersTimeOut = null;
        this.page = 1;
        this.items = undefined;
        this.loadData();
      }, 450);
    },
    date: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
  },
  methods: {
    getColor(i) {
      let e = i - this.colors.length * Math.floor(i / this.colors.length);
      return this.colors[e];
    },
    loadMore() {
      this.page++;
      this.loadData();
    },
    formatDate(val) {
      return date.format(val, true, true);
    },
    showAccountsModal() {
      this.$refs.accountsModal.showModal();
    },
    connect() {
      this.$refs.connectModal.showModal();
    },
    bankList(countryCode) {
      http
        .fetch("/payments/bank-list", {
          country_code: countryCode,
        })
        .then((data) => {
          this.banks = data;
        });
    },
    connectRequest(id, provider) {
      http
        .fetch("/payments/connect", {
          code: id,
          provider: provider,
        })
        .then((data) => {
          window.location.href = data.link;
        });
    },
    updateAccount(id, e, row) {
      let data = {
        id: id,
      };
      data[row] = e;

      http.fetch("/payments/accounts", data, true, "PUT");
    },
    valUpdated(val, row, id, item) {
      let update = { payment: id, invoice: item.invoice_id };
      update[row] = val;

      http.fetch("/payments", update, true, "PUT");
    },
    getUrl(exportUrl = false) {
      let url =
        `/payments${exportUrl ? "/export/{type}" : ""}?page=` +
        (exportUrl ? 1 : this.page) +
        (this.warehouse ? "&warehouse=" + this.warehouse : "") +
        (this.q ? "&q=" + this.q : "") +
        (this.date[0]
          ? "&from=" + this.date[0].toISOString().split("T")[0]
          : "") +
        (this.date[1] ? "&to=" + this.date[1].toISOString().split("T")[0] : "");

      if (this.user !== null && this.user !== undefined) {
        url += `&customer=${this.user}`;
      }

      if (Object.keys(this.filters).length > 0) {
        for (const filter in this.filters) {
          if (Array.isArray(this.filters[filter].value)) {
            for (const value of this.filters[filter].value) {
              url += `&${filter}[]=${value}`;
            }
          } else {
            url += `&${filter}=${this.filters[filter].value}`;
          }
        }
      }
      return url;
    },
    loadData() {
      const loadID = (this.loadID = Math.random());

      if (this.firstLoad) {
        this.items = undefined;
        http.fetch("/companies").then((data) => {
          this.companies = data;
        });
        http.fetch("/payments/accounts").then((data) => {
          this.accounts = data;
        });
        http.fetch("/payments/cards").then((data) => {
          this.mcc = data.mcc;
          this.cards = data.cards.map((e) => {
            e.full = e.last_digits + " " + e.name;
            return e;
          });
        });
      }
      http.fetch(this.getUrl()).then((data) => {
        if (this.loadID !== loadID) {
          return;
        }
        if (this.page === 1) {
          this.rows = lineOptions.init(data.rows, data.line_options);

          let status = this.rows.find((e) => e.key === "payment_status");
          status.values = PaymentStatuses;
          status.center = true;

          status = this.rows.find((e) => e.key === "invoice_payment_status");
          status.values = PaymentStatuses;
          status.center = true;

          status = this.rows.find((e) => e.key === "payment_category_id");
          status.values = data.categories;

          this.lineOptions = data.line_options;
          this.selectedLineOption = data.line_options[0].id;
          this.items = data.data.data;
          this.categories = data.categories;
        } else {
          for (const row of data.data) {
            this.items.push(row);
          }
        }
        this.tableKey++;

        if (this.firstLoad) {
          let filterOptions = [];
          filterOptions.push({
            name: "Show only",
            type: "string",
            key: "group",
            value: {},
            values: [
              {
                id: "payments",
                value: "Payments",
              },
              {
                id: "invoices",
                value: "Invoices",
              },
              {
                id: "no_payments",
                value: "Invoices without payment",
              },
              {
                id: "no_invoices",
                value: "Payments without invoices",
              },
            ],
          });
          filterOptions.push({
            name: "Cashflow type",
            type: "string",
            key: "cashflow_type",
            value: {},
            values: [
              {
                id: 1,
                value: "Revenue",
              },
              {
                id: 2,
                value: "Expense",
              },
              {
                id: 3,
                value: "Refund",
              },
              {
                id: 4,
                value: "Money back",
              },
            ],
          });
          filterOptions.push({
            name: "Payment type",
            type: "string",
            key: "payment_type",
            value: {},
            values: Object.values(this.paymentTypes),
            valueName: "name",
          });
          filterOptions.push({
            name: "Currency",
            type: "string",
            key: "currency",
            value: {},
            values: this.currencies.map((e) => {
              return {
                id: e.code,
                value: e.code,
              };
            }),
          });
          filterOptions.push({
            name: "Accounts",
            type: "string",
            key: "account",
            value: {},
            values: this.accounts.filter(
              (e) => e.is_inactive === null || e.is_inactive === false
            ),
            valueName: "name",
          });
          filterOptions.push({
            name: "Companies",
            type: "string",
            key: "company",
            value: {},
            values: this.companies,
            valueName: "name",
          });
          filterOptions.push({
            name: "Cards",
            type: "string",
            key: "cards",
            value: {},
            values: this.cards,
            valueName: "full",
          });
          filterOptions.push({
            name: "Category",
            type: "string",
            key: "payment_category",
            value: {},
            values: this.categories,
            valueName: "name",
          });
          filterOptions.push({
            name: "Card payment category",
            type: "string",
            key: "mcc_code",
            value: {},
            values: this.mcc,
            valueName: "description",
          });

          this.$store.commit("setFilterPage", "payments");
          this.$store.commit("setFilterOptions", filterOptions);
          this.$store.commit("setFilterOptionsStock", false);
          this.$store.commit("setFilterOptionsPrice", false);
          this.firstLoad = false;
        }
      });
    },
  },
};
</script>
