import i18n from "../i18n";

const PaymentTypes = {
  0: {
    id: 0,
    name: i18n.global.t("paymentTypes.cashWithdraw"),
    color: "warning text-black",
    icon: "wallet-empty",
  },
  1: {
    id: 1,
    name: i18n.global.t("paymentTypes.cardPayment"),
    color: "successful text-white",
    icon: "wallet-money",
  },
  2: {
    id: 2,
    name: i18n.global.t("paymentTypes.refund"),
    color: "light text-white",
    icon: "wallet-time",
  },
  3: {
    id: 3,
    name: i18n.global.t("paymentTypes.chargeback"),
    color: "info text-white",
    icon: "wallet-change",
  },
  4: {
    id: 4,
    name: i18n.global.t("paymentTypes.exchange"),
    color: "light text-white",
    icon: "wallet-change",
  },
  5: {
    id: 5,
    name: i18n.global.t("paymentTypes.wireTransfer"),
    color: "primary text-white",
    icon: "wallet-change",
  },
  6: {
    id: 6,
    name: i18n.global.t("paymentTypes.loan"),
    color: "failed text-white",
    icon: "wallet-empty",
  },
  7: {
    id: 7,
    name: i18n.global.t("paymentTypes.topup"),
    color: "failed text-white",
    icon: "wallet-change",
  },
  8: {
    id: 8,
    name: i18n.global.t("paymentTypes.adjusment"),
    color: "badge-outline text-white",
    icon: "wallet-time",
  },
  9: {
    id: 9,
    name: i18n.global.t("paymentTypes.fee"),
    color: "failed text-white",
    icon: "wallet-change",
  },
  10: {
    id: 10,
    name: i18n.global.t("paymentTypes.reversal"),
    color: "failed text-white",
    icon: "wallet-change",
  },
};

export default PaymentTypes;
